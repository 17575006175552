import React from 'react'
import Modal from 'react-bootstrap/Modal';
import BaseModal from './base-modal';
import { isIphone, isSafari } from '../../helpers'

class ModalPDF extends BaseModal {  
  constructor(props) {
    super(props);
    this.baseUrl = 'https://drive.google.com/viewerng/viewer?embedded=true&url='
    this.calcHeight = this.calcHeight.bind(this)
    this.state = {
      height: 200,
    }
  }
  
  /**
   * Calcs optimal height for modal
   * @return {int}
   */
  calcHeight() {
    try {
      if (this.bodyRef) {
        const modalHeader = this.bodyRef.previousSibling
        const modalParent = this.bodyRef.parentNode.parentNode
        const modalHeaderHeight = modalHeader.offsetHeight
        const modalParentStyle = modalParent.currentStyle || window.getComputedStyle(modalParent);
        const modalBodyStyle = this.bodyRef.currentStyle || window.getComputedStyle(this.bodyRef)
        return window.innerHeight - (
          parseInt(modalBodyStyle.paddingTop) + 
          parseInt(modalBodyStyle.paddingBottom) + 
          parseInt(modalParentStyle.marginTop) + 
          parseInt(modalParentStyle.marginBottom) + 
          modalHeaderHeight 
        )
      }
    } catch (e) {
      console.error(e)
      // Default height
      return 200
    }
  }
  
  /**
   * Shows the modal
   * @return { void }
   */
  showModal() {
    super.showModal(() => {
      this.setState({
        height: this.calcHeight()
      })
    })
  }

  render() {
    const url = `${this.baseUrl}https:${encodeURIComponent(this.props.url)}&v=${Math.random()}`
    return (
      <Modal show={this.state.show} onHide={this.closeModal} size="xl" id='modal-pdf'>
        <Modal.Header closeButton>
          {/* <Modal.Title className="text-primary">{ this.props.title }</Modal.Title> */}
        </Modal.Header>
        <Modal.Body ref={ (ref) => this.bodyRef = ref }>
          {/* This is a workaround for iphone devices and safari browsers, because ios automatically convert the PDF to an image with only the first page of the PDF. */}
          {/* We decide to avoid the use of Google Docs Viewer because his instability */}
          { (isIphone() || isSafari()) ? (
            <p className="text-primary">It seems either your Device or your Browser does not support PDFs. Please <a href={`https:${this.props.url}`} target="_blank"><u>Download the PDF</u></a> to view it correctly.</p>
          ) : ""}
          <object width="100%" height={this.state.height} data={`https:${this.props.url}`} type="application/pdf">
            <embed width="100%" height={ this.state.height } src={`https:${this.props.url}`} type="text/html" />
          </object>

        </Modal.Body>
      </Modal>
    )
  }
}

export default ModalPDF