import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { get } from 'lodash'
import Helmet from 'react-helmet'
import Layout from '../../components/theme/layout'
import SEO from '../../components/seo'
import HeroImage from '../../components/contentful/hero-image'
import ModalPDF from '../../components/modals/modal-pdf'
import { buildBreadcrumbsItem } from '../../helpers'
import styles from '../../scss/page.scss'

class COAsPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Certificates Of Analysis | Honest Paws®'
    this.pageUrl = this.props.location.href
    this.seo = {
      title: title,
      meta: {
        title: title
      },
      children: [
        <link rel="canonical" href={ this.pageUrl } key='canonical-url' />
      ]
    }

    this.breadcrumbs = [
      buildBreadcrumbsItem('Certificates Of Analysis', this.props.location.href)
    ]

    this.stepsImages = get(this, 'props.data.stepsImages.edges', [])
    this.steps = [
      '1. Scan QR Code',
      '2. Select Product Type',
      '3. Select Batch Number',
      '4. Submit to View Certificate of Analysis',
    ]
    this.state = {
      'product-type': '',
      'product-batch': '',
      currentCertificateUrl: ''
    }
    this.certificates = get(this, 'props.data.certificates.edges', [])

    this.productTypes = {}
    this.certificates.forEach(({node}) => {
      const certificate = node
      if (!this.productTypes[certificate.productType]) {
        this.productTypes[certificate.productType] = {}
      }
      this.productTypes[certificate.productType][certificate.batchNumber] = certificate.file.file.url
    })

    this.handleProductTypeChange = this.handleProductTypeChange.bind(this)
    this.handleProductBatchChange = this.handleProductBatchChange.bind(this)
    this.viewPDF = this.viewPDF.bind(this)

  }

  /**
   * Function triggered when product type changes
   * @param  {Event} e
   * @return {void}
   */
  handleProductTypeChange(e) {
    const value = e.currentTarget.value
    this.setState({
      'product-type': value,
      'product-batch': ''
    })
  }

  /**
   * Function triggered when batch changes
   * TODO unify with function above (may be using `resetSelect` prop)
   * @param  {Event} e
   * @return {void}
   */
  handleProductBatchChange(e) {
    const value = e.currentTarget.value
    this.setState({
      'product-batch': value
    })
  }

  /**
   * Shows PDF Modal
   * @return {void}
   */
  viewPDF() {
    if (this.state['product-type'] && this.state['product-batch']) {
      this.setState({
        currentCertificateUrl: this.productTypes[this.state['product-type']][this.state['product-batch']]
      }, () => {
        if (this.pdfViewer) {
          this.pdfViewer.showModal()
        }
      })
    }
  }

  render() {
    return (
      <Layout containerClassName={`page page-verify`} hideFooter={true} hideHeader={true} location={this.props.location} >
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <section className="page-title-and-summary page-header text-center px-4 ">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h1 className="mb-0">Certificates of Analysis</h1>
              <div className="page-summary mt-3">
                We make it easy to view any Certificate of Analysis for your product of choice
              </div>
              <a href="/collections/" target="_top">Testing link</a>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="container page-content-container text-center">
            <div className="row">
              { this.stepsImages.map(({node}, index) => {
                return (
                  <div className="col-12 col-md-3 mt-5 mt-md-0"  key={ index } >
                    <Img fixed={ node.childImageSharp.fixed } className="img-fluid" alt={ this.steps[index] }/>
                    <p>{ this.steps[index] }</p>
                  </div>
                )
              }) }
            </div>
            <div className="row mt-6 text-left">
              <div className="col-12 col-md-4 mt-5 mt-md-0">
                <label htmlFor="product-type">Select your product type</label>
                <select className="form-control" id="product-type" name="product-type" value={this.state['product-type']} onChange={this.handleProductTypeChange}>
                  <option value="">Select Product Type</option>
                  { Object.keys(this.productTypes).map((type, index) => (
                    <option value={type} key={index}>{ type }</option>
                  )) }
                </select>
              </div>
              <div className="col-12 col-md-4 mt-5 mt-md-0">
                <label htmlFor="product-batch">Select Batch Number</label>
                <select className="form-control" id="product-batch" name="product-batch" onChange={this.handleProductBatchChange} value={this.state['product-batch']}>
                  <option value="">Select Batch Number</option>
                  { this.state['product-type'] && this.productTypes[this.state['product-type']] ? Object.keys(this.productTypes[this.state['product-type']]).map((batch, index) => (
                    <option value={batch} key={index}>{ batch }</option>
                  )) : ''}
                </select>
              </div>
              <div className="col-12 col-md-4 mt-5 mt-md-0">
                <label htmlFor="product-coa">Submit to view COA</label>
                <button className="btn btn-success btn-block btn-square" id="product-coa" onClick={this.viewPDF}>Submit</button>
              </div>
            </div>
          </div>
        </section>
        <ModalPDF url={this.state.currentCertificateUrl} ref={(ref) => this.pdfViewer = ref}/>
      </Layout>
    )
  }
}

export default COAsPage

export const COAsIframePageQuery = graphql`
  query COAsIframePageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    stepsImages: allFile(filter: {relativeDirectory: {eq: "coas"}}, sort: {order: ASC, fields: name}) {
      edges {
        node {
          childImageSharp {
            fixed(width: 140) {
              ...GatsbyImageSharpFixed
            }
          }
          name
        }
      }
    }
    certificates: allContentfulCertificateOfAnalysis {
      edges {
        node {
          ...ContentfulCertificateOfAnalysis
        }
      }
    }
  }
`